import React, {useEffect, useState} from 'react';
import {Button, Flex, Form, Input, message, Modal, Spin, Typography, Upload} from 'antd';
import {CloseCircleTwoTone, PlusOutlined} from '@ant-design/icons';
import axios from 'axios';
import useFields from "../hooks/useFields";
import {StyledButton} from './styled/Button';
import {CreateResumeButton} from "./buttons/CreateResumeButton";
import {DeleteResumeButton} from "./buttons/DeleteResumeButton";
import * as resumeModel from "../model/resume";
import {useUnit} from 'effector-react';
import {StartupDialog} from "./StartupDialog";
import {StyledFormContainer} from './styled/FormContainer';
import {StyledFormItemsContainer} from './styled/FormItemsContainer';
import {SpinnerOverlay} from './styled/SpinnerOverlay';
import {downloadDOCX, downloadPDF} from '../api/documents';
import {customizeRequiredMark} from './customizeRequiredMark';
import {FieldsToolbar} from './FieldsToolbar';

// TODO отправлять на сервер все поля, доступные на клиенте

type RequiredMark = boolean | 'optional' | 'customize';

const MainForm: React.FC<{ setResult: React.Dispatch<React.SetStateAction<string>> }> = ({setResult}) => {
    const [promptValue, setPromptValue] = useState('');
    const [form] = Form.useForm();
    const [requiredMark, setRequiredMarkType] = useState<RequiredMark>();
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingParseResume, setIsLoadingParseResume] = useState<"pending" | "finished" | "error" | null>(null);
    const [photo, setPhoto] = useState<any>("");
    const [htmlContent, setHtmlContent] = useState("");
    const [isStartupPageVisible, setIsStartupPageVisible] = useState(true);

    const [resumeInstance] = useUnit([resumeModel.$resume])

    const {components, toolbar, addManyFields} = useFields([])
    const [parserResult, setParserResult] = useState({})

    const onRequiredTypeChange = ({requiredMarkValue}: { requiredMarkValue: RequiredMark }) => {
        setRequiredMarkType(requiredMarkValue);
    };

    const [isPromptPopupOpen, setIsPromptPopupOpen] = useState(false);

    const handleOpenPromptPopup = () => {
        setIsPromptPopupOpen(true);
    };

    const handleClosePromptPopup = () => {
        setIsPromptPopupOpen(false);
    };

    const handleFieldSubmit = async (fieldName: string, promptValue: string = '') => {
        try {
            const fieldValue = form.getFieldValue(fieldName);
            if (!fieldValue) {
                message.error(`Field ${fieldName} is empty!`);
                return;
            }

            setIsLoading(true);

            const formattedData = {
                messages: [{message: JSON.stringify({[fieldName]: fieldValue, prompt: promptValue})}]
            };

            const res = await axios.post('/api/chatfield', formattedData, {
                headers: {'Content-Type': 'application/json'}
            });

            const result = res.data;

            message.success(`Field ${fieldName} updated successfully!`);
        } catch (error) {
            console.error("Error submitting field:", error);
            message.error("Failed to submit field.");
        } finally {
            setIsLoading(false);
        }
    };

    const handleSubmit = async () => {
        try {
            setIsLoading(true);
            const values = await form.validateFields();

            const formattedData = {
                model: "mistral:7b",
                messages: [{
                    message: JSON.stringify(values)
                }]
            };

            const res = await axios.post('/api/chat', formattedData, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            const htmlResult = res.data;
            setResult(htmlResult);
            setHtmlContent(htmlResult);

            message.success('Form submitted successfully!');
        } catch (error: any) {
            if (axios.isAxiosError(error)) {
                console.error("Axios error details:", error.response?.data);
                message.error(`Form submission failed! ${error.response?.data?.message || error.message}`);
            } else {
                message.error(`Form submission failed! ${error.message}`);
            }
        } finally {
            setIsLoading(false);
        }
    };

    const handlePhotoChange = ({fileList}: any) => {
        if (fileList.length > 0)
            setPhoto(fileList[0]);
    };

    useEffect(() => {
        if (isLoadingParseResume === "finished") {
            form.setFieldsValue(parserResult);
            setIsLoading(false);
            setIsLoadingParseResume(null)
        }
    }, [form, isLoadingParseResume, parserResult])

    if (isStartupPageVisible && !resumeInstance) {
        return <StartupDialog
            form={form}
            setParserResult={setParserResult}
            addManyFields={addManyFields}
            setIsStartupPageVisible={setIsStartupPageVisible}
            setIsLoading={setIsLoading}
            setIsLoadingParseResume={setIsLoadingParseResume}
        />
    }

    return (
        <StyledFormContainer>
            <SpinnerOverlay isLoading={isLoading}>
                <Spin size="large"/>
            </SpinnerOverlay>
            <Flex justify="space-between" wrap="wrap" style={{marginBottom: 20, width: "100%"}}>
                <CreateResumeButton form={form}/>
                <DeleteResumeButton/>
            </Flex>
            <Typography.Title level={3}>Personal Details</Typography.Title>
            <Form
                form={form}
                layout="vertical"
                initialValues={{requiredMarkValue: 'optional', ...(resumeInstance || {})}}
                onValuesChange={onRequiredTypeChange}
                requiredMark={requiredMark === 'customize' ? customizeRequiredMark : requiredMark}
            >
                <StyledFormItemsContainer>

                    <Form.Item label="Wanted Job Title" tooltip="Some text" name="jobTitle" rules={[{required: true}]}>
                        <Input
                            placeholder="e.g. Teacher"
                            addonAfter={<FieldsToolbar
                                fieldName={"jobTitle"}
                                handleFieldSubmit={handleFieldSubmit}
                                promptValue={promptValue}
                                handleOpenPromptPopup={handleOpenPromptPopup}
                            />}
                        />
                    </Form.Item>


                    <Form.Item label="Upload photo" valuePropName="fileList"
                               getValueFromEvent={(e) => (Array.isArray(e) ? e : e?.fileList)}>
                        <Upload
                            listType="picture-card"
                            fileList={photo ? [photo] : []}
                            onChange={handlePhotoChange}
                            maxCount={1}
                        >
                            {photo.length >= 1 ? null : (
                                <div>
                                    <PlusOutlined/>
                                    <div style={{marginTop: 8}}>Upload</div>
                                </div>
                            )}
                        </Upload>
                    </Form.Item>

                    <Form.Item label="First Name" name="firstName" rules={[{required: true}]}>
                        <Input
                            placeholder="John Doe"
                            addonAfter={<FieldsToolbar fieldName={"firstName"} handleFieldSubmit={handleFieldSubmit}
                                                       promptValue={promptValue}
                                                       handleOpenPromptPopup={handleClosePromptPopup}/>}
                        />
                    </Form.Item>
                    <Modal
                        title="Уточните промпт"
                        open={isPromptPopupOpen}
                        onOk={handleClosePromptPopup}
                        onCancel={handleClosePromptPopup}
                    >
                        <Input
                            value={promptValue}
                            onChange={(e) => setPromptValue(e.target.value)}
                            placeholder="Введите уточнение"
                        />
                    </Modal>

                    <Form.Item label="Email" name="email" rules={[{required: true, type: 'email'}]}>
                        <Input
                            placeholder="example@exmail.com"
                            addonAfter={<FieldsToolbar fieldName={"email"} handleFieldSubmit={handleFieldSubmit}
                                                       promptValue={promptValue}
                                                       handleOpenPromptPopup={handleClosePromptPopup}/>}
                        />
                    </Form.Item>

                    <Form.Item label="Country" name="country" rules={[{required: true}]}>
                        <Input
                            placeholder="e.g. United States"
                            addonAfter={<FieldsToolbar fieldName={"country"} handleFieldSubmit={handleFieldSubmit}
                                                       promptValue={promptValue}
                                                       handleOpenPromptPopup={handleClosePromptPopup}/>}
                        />
                    </Form.Item>
                    <Form.Item label="Last Name" name="lastName" rules={[{required: true}]}>
                        <Input
                            addonAfter={<FieldsToolbar fieldName={"lastName"} handleFieldSubmit={handleFieldSubmit}
                                                       promptValue={promptValue}
                                                       handleOpenPromptPopup={handleClosePromptPopup}/>}
                        />
                    </Form.Item>

                    <Form.Item label="Phone" name="phone" rules={[{required: true}]}>
                        <Input
                            addonAfter={<FieldsToolbar fieldName={"phone"} handleFieldSubmit={handleFieldSubmit}
                                                       promptValue={promptValue}
                                                       handleOpenPromptPopup={handleClosePromptPopup}/>}
                        />
                    </Form.Item>

                    <Form.Item label="City" name="city" rules={[{required: true}]}>
                        <Input
                            addonAfter={<FieldsToolbar fieldName={"city"} handleFieldSubmit={handleFieldSubmit}
                                                       promptValue={promptValue}
                                                       handleOpenPromptPopup={handleClosePromptPopup}/>}
                        />
                    </Form.Item>

                    {components}
                </StyledFormItemsContainer>
                <Typography.Title level={3}>Professional Summary</Typography.Title>

                <Form.Item label="Professional Summary" name="description" rules={[{required: true}]}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <Input.TextArea
                            placeholder="Write your professional summary here..."
                            autoSize={{minRows: 4, maxRows: 8}}
                            style={{flex: 1, marginRight: '8px'}}
                        />
                        <FieldsToolbar fieldName={"description"} handleFieldSubmit={handleFieldSubmit}
                                       promptValue={promptValue} handleOpenPromptPopup={handleClosePromptPopup}/>
                    </div>
                </Form.Item>

                <Flex justify={"space-between"} wrap={"wrap"} style={{marginBottom: 20, width: "100%"}}>
                    <StyledButton type="primary" onClick={handleSubmit}>
                        Submit
                    </StyledButton>
                    <StyledButton type="primary" onClick={() => downloadPDF(htmlContent)}>
                        Download PDF
                    </StyledButton>

                    <StyledButton type="primary" onClick={() => downloadDOCX(htmlContent)}>
                        Download DOCX
                    </StyledButton>

                </Flex>

                <Button
                    type="link"
                    style={{position: "absolute", top: 10, right: 10, fontSize: "18px", color: "#1677ff"}}
                    onClick={() => setIsStartupPageVisible(true)}
                >
                    <CloseCircleTwoTone/>
                </Button>

            </Form>

            {toolbar}
        </StyledFormContainer>
    );
};

export default MainForm;

