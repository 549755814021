export const HEADER_HEIGHT = 60

export const staticFields = [
    "jobTitle",
    "firstName",
    "email",
    "country",
    "lastName",
    "phone",
    "city",
    "description"
]