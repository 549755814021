import {createDomain, sample} from "effector";
import {ID, Resume, ResumeShort} from "../types/resume";
import {deleteResume, getResumeById, getResumeList} from "../api/resume";
import {mapResume} from "./mappers/mapResume";

const domain = createDomain("resume")

export const resumeCreated = domain.createEvent<ID>()

export const resumeClicked = domain.createEvent<ID>()

export const resumeDeleteClicked = domain.createEvent<ID>()

export const resumeListRequested = domain.createEvent()

export const $resumeId = domain.store<ID | null>(null)
    .on(resumeCreated, (_, id) => {
        return id
    })
    .on(resumeClicked, (_, id) => {
        return id
    })

export const fetchResumeListFx = domain.createEffect(getResumeList)

export const fetchResumeByIdFx = domain.createEffect(getResumeById)

export const deleteResumeByIdFx = domain.createEffect(deleteResume)

export const $resume = domain.store<Omit<Resume, "id"> | null>(null)

export const $resumeHtmlContent = domain.store<string | null>(null)

export const $resumeList = domain.store<ResumeShort[]>([])
    .on(fetchResumeListFx.done, (state, {result}) => {
        if (result) {
            return result.map(mapResume)
        }

        return state
    })

sample({
    clock: resumeListRequested,
    target: fetchResumeListFx,
})

sample({
    clock: resumeClicked,
    target: fetchResumeByIdFx,
})

sample({
    clock: fetchResumeByIdFx.done,
    fn: ({result}) => {
        if (result) return result.data

        return null
    },
    target: $resume,
})

sample({
    clock: fetchResumeByIdFx.done,
    fn: ({result}) => {
        if (result) return result.htmlContent

        return null
    },
    target: $resumeHtmlContent,
})

sample({
    clock: resumeDeleteClicked,
    target: deleteResumeByIdFx,
})

sample({
    clock: deleteResumeByIdFx.done,
    target: fetchResumeListFx,
})