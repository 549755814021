import {Resume} from "../types/resume";
import {authApi} from "./client/httpsClient";
import {AxiosResponse} from "axios";

export const createResume = async (data: {
    data: Omit<Resume, "id">,
    title: string
}): Promise<Record<string, string> | null> => {
    try {
        const resp = await authApi.post('/api/resumes/create', data);

        return resp.data as Record<string, string>;
    } catch (e) {
        return null;
    }
}

export const deleteResume = async (id: Resume["id"]): Promise<AxiosResponse | null> => {
    try {
        return await authApi.delete(`/api/resumes/delete/${id}`);
    } catch (e) {
        return null;
    }
}

export const getResumeList = async (): Promise<Resume[] | null> => {
    try {
        const {data} = await authApi.get(`/api/resumes/list`)

        return data.resumes;
    } catch (e) {
        return null;
    }
}

export const getResumeById = async (id: Resume["id"]): Promise<{
    data: Omit<Resume, "id">,
    htmlContent: string | null
} | null> => {
    try {
        const {data} = await authApi.get(`/api/resumes/resume/${id}`)

        return data;
    } catch (e) {
        return null;
    }
}