import styled from "@emotion/styled";

export const PdfUploadContainer = styled.div`
    border: 2px dashed #1677ff;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    margin-bottom: 20px;
    text-align: center;
`;

export const PdfUploadDescription = styled.p`
    color: #333;
    font-size: 14px;
    margin-bottom: 16px;
`;