import React, {useEffect, useMemo} from "react";
import {Avatar, Button, Card, Col, Row, Typography} from "antd";
import {DeleteOutlined, DownloadOutlined, EditOutlined} from "@ant-design/icons";
import {useUnit} from "effector-react";
import * as userModel from "../model/user";
import * as resumeModel from "../model/resume";
import {useNavigate} from "react-router";

const {Text, Title} = Typography;

const ProfileDocuments: React.FC = () => {
    const navigate = useNavigate()
    const [userInfo, isAuth, resumeListRequested, resumeList, resumeClicked, resumeDeleteClicked] = useUnit([
        userModel.$userInfo,
        userModel.$isAuth,
        resumeModel.resumeListRequested,
        resumeModel.$resumeList,
        resumeModel.resumeClicked,
        resumeModel.resumeDeleteClicked
    ]);

    // Данные профиля
    const profile = useMemo(() => {
        return {
            name: [
                userInfo?.firstName?.toUpperCase(),
                userInfo?.lastName?.toUpperCase(),
                userInfo?.middleName?.toUpperCase(),
            ]
                .filter(Boolean)
                .join(" ") || "ФИО не указано",
            position: userInfo?.about || "Пока не указано...",
            email: userInfo?.email || "Email не указано",
            phone: userInfo?.phone || "Телефон не указан",
            avatar: "https://via.placeholder.com/100",
        };
    }, [userInfo])

    useEffect(() => {
        if (isAuth) {
            resumeListRequested()
        }
    }, [isAuth, resumeListRequested]);

    return (
        <div style={{maxWidth: 1000, margin: "auto", marginTop: 20, padding: 20}}>
            {/* Блок профиля */}
            <Card
                style={{
                    background: "#EAF4FC",
                    borderRadius: 10,
                    padding: 20,
                    marginBottom: 20,
                    boxShadow: "0 4px 10px rgba(0,0,0,0.1)",
                }}
            >
                <Row align="middle">
                    <Col flex="100px" style={{marginRight: 20}}>
                        <Avatar size={100} src={profile.avatar}/>
                    </Col>
                    <Col flex="auto">
                        <Title level={3} style={{marginBottom: 5}}>{profile.name}</Title>
                        <Text type="secondary">{profile.position}</Text>
                        <div style={{marginTop: 10}}>
                            <Text>Email: {profile.email}</Text> <br/>
                            <Text>Телефон: {profile.phone}</Text>
                        </div>
                    </Col>
                </Row>
            </Card>

            {/* Основная часть с документами */}
            <div style={{display: "flex"}}>
                {/* Левая колонка с категориями */}
                <div style={{width: 200, paddingRight: 20}}>
                    <Title level={3}>My Documents</Title>
                    <Button type="primary" block style={{marginBottom: 10}}>
                        Resumes
                    </Button>
                    <Button block>Cover Letters</Button>
                </div>

                {/* Центральный контент с документами */}
                <div style={{flex: 1}}>
                    <Row gutter={[16, 16]}>
                        {resumeList.map((resume) => (
                            <Col span={24} key={resume.id}>
                                <Card
                                    styles={{
                                        cover: {
                                            display: "flex",
                                            alignItems: "center",
                                            padding: 15,
                                            borderRadius: 8,
                                            boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
                                            boxSizing: "border-box",
                                        },
                                        body: {
                                            width: '100%',
                                        }
                                    }}
                                >
                                    <div style={{display: "flex", alignItems: "center", width: "100%"}}>
                                        {/* Мини-превью документа */}
                                        <div
                                            style={{
                                                width: 120,
                                                height: 150,
                                                background: "#f0f0f0",
                                                borderRadius: 5,
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                marginRight: 15
                                            }}
                                        >
                                            <Text strong>Preview</Text>
                                        </div>
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            flex: "1 1 0",
                                            alignSelf: "stretch"
                                        }}>
                                            {/* Кнопки действий */}
                                            <div style={{display: "flex", gap: 10, alignSelf: "flex-end"}}>
                                                <Button
                                                    icon={<EditOutlined/>}
                                                    onClick={() => {
                                                        resumeClicked(resume.id)
                                                        navigate("/")
                                                    }}
                                                />
                                                <Button icon={<DownloadOutlined/>}/>
                                                <Button
                                                    danger icon={<DeleteOutlined/>}
                                                    onClick={() => {
                                                        resumeDeleteClicked(resume.id)
                                                    }}
                                                />
                                            </div>

                                            <Title level={5}>{resume.title}</Title>

                                            <Text type="secondary">
                                                Updated: {new Date(resume.updatedAt).toString()}
                                            </Text>
                                        </div>
                                    </div>


                                </Card>
                            </Col>
                        ))}
                    </Row>
                </div>
            </div>
        </div>
    );
};

export default ProfileDocuments;
