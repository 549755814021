import styled from "@emotion/styled";

export const SpinnerOverlay = styled.div`
    display: ${({isLoading}: { isLoading: boolean }) => (isLoading ? 'flex' : 'none')};
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    justify-content: center;
    align-items: center;
    z-index: 1000;
`;