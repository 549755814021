import styled from "@emotion/styled";
import {Button} from "antd";

export const StyledButton = styled(Button)`
    border: none;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    cursor: pointer;
    border-radius: 4px;
`;
