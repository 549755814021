import {HEADER_HEIGHT, staticFields} from "../constants";
import {Button, Flex, Form, FormInstance, Input, message, Modal, Spin, Typography, Upload} from "antd";
import {StyledButton} from "./styled/Button";
import {CloseCircleTwoTone, UploadOutlined} from "@ant-design/icons";
import React, {FC, useState} from "react";
import fieldsMatcher from "./fields";
import axios from "axios";
import {Fields} from "./fields/types";
import {PdfUploadContainer, PdfUploadDescription} from "./styled/Pdf";

interface StartupDialogProps {
    form: FormInstance
    setParserResult: React.Dispatch<React.SetStateAction<{}>>
    addManyFields: (fieldNames: Fields[]) => void
    setIsStartupPageVisible: React.Dispatch<React.SetStateAction<boolean>>
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
    setIsLoadingParseResume: React.Dispatch<React.SetStateAction<"pending" | "finished" | "error" | null>>
}

export const StartupDialog: FC<StartupDialogProps> = ({
                                                          form,
                                                          setParserResult,
                                                          addManyFields,
                                                          setIsStartupPageVisible,
                                                          setIsLoading,
                                                          setIsLoadingParseResume,
                                                      }) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [pdf, setPdf] = useState<any>("");
    const [showFullGenModal, setShowFullGenModal] = useState(false);

    const handleStartupSubmit = async (url: string) => {
        setIsSubmitting(true);
        try {
            setIsLoading(true);
            setIsLoadingParseResume("pending");

            const textData = form.getFieldValue("startupText");
            await form.validateFields();

            const fields = [...Object.keys(fieldsMatcher), ...staticFields];

            const formData = new FormData();
            formData.append("text", textData);
            formData.append("fields[]", JSON.stringify(fields));

            const response = await axios.post(url, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            const filledFields = response.data;

            const {description, ...others} = filledFields
            form.setFieldValue("description", description);
            const dynamicFields = Object.keys(others).filter((field) => {
                if (!staticFields.includes(field)) return field;

                return false
            })

            addManyFields(dynamicFields as Fields[])

            setParserResult(filledFields)
            setIsLoadingParseResume("finished");

            message.success("Text data processed successfully!");
            setIsStartupPageVisible(false);
        } catch (error) {
            console.error("Error processing text data:", error);
            message.error("Failed to process text data.");
            setIsLoadingParseResume("error");
        } finally {
            setIsSubmitting(false);
            setIsLoading(false);
        }
    };

    const handlePdfChange = ({fileList}: any) => {
        if (fileList.length > 0)
            setPdf(fileList[0]);
    };

    const handlePdfUpload = async () => {
        if (pdf.length === 0) {
            message.error("Please select a PDF file first!");
            return;
        }

        const formData = new FormData();
        formData.append("file", pdf.originFileObj);
        formData.append("fields[]", JSON.stringify([...Object.keys(fieldsMatcher), ...staticFields]));

        try {
            setIsLoading(true);
            setIsLoadingParseResume("pending");

            const response = await axios.post('/api/parseResume', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            const filledFields = response.data

            const {description, ...others} = filledFields
            form.setFieldValue("description", description);
            const dynamicFields = Object.keys(others).filter((field) => {
                if (!staticFields.includes(field)) return field;

                return false
            })

            addManyFields(dynamicFields as Fields[])

            setParserResult(filledFields)
            message.success("PDF uploaded and parsed successfully!");
        } catch (error) {
            console.error("Error uploading PDF:", error);
            message.error("Failed to upload and parse the PDF.");
        } finally {
            setIsLoadingParseResume("finished");
        }
    };

    return (
        <div style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: `calc(100vh - ${HEADER_HEIGHT}px)`,
            width: "100dvw",
            padding: "20px",
            position: "absolute",
            zIndex: 10,
            backgroundColor: "white",
            boxSizing: "border-box"
        }}>
            {isSubmitting && (
                <div style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    backgroundColor: "rgba(255, 255, 255, 0.8)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    zIndex: 1000
                }}>
                    <Spin size="large"/>
                </div>
            )}

            <Form
                form={form}
                layout="vertical"
                style={{width: "100%", maxWidth: "600px"}}
            >
                <Form.Item
                    name="startupText"
                    rules={[{
                        required: true,
                        message: "Please enter text about yourself."
                    }]} // Проверка на заполнение
                >
                    <Input.TextArea
                        placeholder="Enter any information about yourself, including work experience, hobbies, and anything else that might help create a resume."
                        style={{fontSize: "16px"}}
                        rows={8}
                    />
                </Form.Item>

                <Form.Item>
                    <Flex justify={"space-between"} wrap={"wrap"} style={{marginBottom: 20, width: "100%"}}>
                        <StyledButton
                            type="primary"
                            style={{backgroundColor: "#1677ff", color: "white"}}
                            onClick={() => handleStartupSubmit("/api/parseResume")}
                            disabled={isSubmitting}
                        >
                            {isSubmitting ? <Spin size="small"/> : "Submit"}
                        </StyledButton>
                        <Button onClick={() => setShowFullGenModal(true)}>Magic Generation</Button>
                        <Modal
                            open={showFullGenModal}
                            onOk={() => handleStartupSubmit("/api/parseRandResume")}
                            onCancel={() => setShowFullGenModal(false)}
                        >
                            <Typography>При недостатке данных могут быть сгенерированы ложные данные. Будьте
                                внимательны.</Typography>
                        </Modal>
                    </Flex>

                </Form.Item>
                <Button
                    type="link"
                    style={{position: "absolute", top: 10, right: 10, fontSize: "18px", color: "#1677ff"}}
                    onClick={() => setIsStartupPageVisible(false)}
                >
                    <CloseCircleTwoTone/>
                </Button>
                <PdfUploadContainer>
                    <PdfUploadDescription>
                        Upload your resume to automatically fill the form fields.
                    </PdfUploadDescription>

                    <Form.Item valuePropName="fileList"
                               getValueFromEvent={(e) => (Array.isArray(e) ? e : e?.fileList)}>
                        <Upload
                            accept=".pdf"
                            fileList={pdf ? [pdf] : []}
                            onChange={handlePdfChange}
                            maxCount={1}
                        >
                            <Button icon={<UploadOutlined/>}>Select PDF</Button>
                        </Upload>
                    </Form.Item>

                    <StyledButton type="default" onClick={handlePdfUpload} disabled={!pdf}>
                        Upload PDF
                    </StyledButton>
                </PdfUploadContainer>
            </Form>
        </div>
    );
}
